/* eslint-disable */
  const required = (propertyType, customErrorMessage) => {
    return v => (v && v.length > 0) || customErrorMessage || `Bạn phải điền dữ liệu vào ${propertyType}`
  }
  const minLength = (propertyType, minLength) => {
    return v => {
      if (!v) { return true }

      return v.length >= minLength || `${propertyType} phải ít nhất ${minLength} kí tự`
    }
  }
  const maxLength = (propertyType, maxLength) => {
    return v => v && v.length <= maxLength || `${propertyType} phải ít hơn ${maxLength} kí tự`
  }

  const emailFormat = () => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,24})+$/
    return v => (v && regex.test(v)) || 'Thông tin email không hợp lệ !'
  }

  const phoneFormat = () => {
    const regex = /((09|03|07|08|05)+([0-9]{8})\b)/g
    return v => (v && regex.test(v)) || 'Số điện thoại không hợp lệ !'
  }

  const loginAccount = () =>{
    const regexE = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,24})+$/
    const regexP = /((09|03|07|08|05)+([0-9]{8})\b)/g
    return v => (v && (regexE.test(v) || regexP.test(v))) || 'Tài khoản không hợp lệ !'
  }
  const rePassword = (oldpassword) => {
    return v => v === oldpassword || 'Mật khẩu xác thực không trùng khớp với mật khẩu !'
  }
  const min = (val) => {
    return v => v >= val || `Giá đấu phải lớn hơn hoặc bằng ${val}`
  }
  export default {
    required,
    minLength,
    maxLength,
    emailFormat,
    phoneFormat,
    rePassword,
    min,
    loginAccount,
  }
