<template>
  <div :class="[{ 'sale-page-one': $route.path == '/sale-page-one'}, { 'sale-page-two': $route.path == '/sale-page-two'} ]">
    <div class="secondary white--text top-bar">
      <v-container>
        <div class="d-flex justify-space-between align-center">
          <v-toolbar-title>
            <v-img
              class="me-2 logo d-md-none d-block"
              width="98"
              src="@/assets/images/logo.svg"
              alt=""
            />
            <div class="mr-2 d-md-block d-none">
              <v-icon
                class="mr-2 white--text"
                small
              >
                mdi-currency-jpy
              </v-icon>
              <span class="text-sm mr-5">Tỷ giá: 1¥ = {{ System.TiGia }} VND</span>
            </div>
          </v-toolbar-title>
          <v-toolbar-title>
            <span class="white--text text-sm mr-5 d-md-inline-block d-none">Theme FAQ's</span>
            <span class="white--text text-sm mr-5 d-md-inline-block d-none">Need Help ?</span>
          </v-toolbar-title>
        </div>
      </v-container>
    </div>
    <v-app-bar
      app
      scroll-off-screen
      class="sub-topbar"
      color="white py-1"
    >
      <v-container>
        <div class="d-flex justify-space-between align-center">
          <v-toolbar-title class="d-flex align-center">
            <router-link to="/">
              <v-img
                class="me-2 logo d-md-block d-none"
                width="98"
                src="@/assets/images/logo.svg"
                alt=""
              />
            </router-link>
            <div class="dropdown-ecommerce">
              <header-navbar />
            </div>
          </v-toolbar-title>
          <v-col
            cols="12"
            md="7"
          >
            <div class="search-bar d-flex p-relative">
              <v-text-field
                class=""
                placeholder="Searching For"
                filled
                rounded
                dense
                prepend-inner-icon="mdi-magnify"
              />
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn

                    color="white"
                    class="text-capitalize search-bar-dropdown"
                    v-bind="attrs"
                    v-on="on"
                  >
                    All Categories
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in items"
                    :key="index"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-col>
          <div class="">
            <div class="d-md-block d-none">
              <template v-if="!isAuthenticated">
                <v-dialog
                  v-model="dialog"
                  width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      elevation="0"
                      fab
                      small
                      class="mr-3 "
                      color="grey lighten-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-account</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-form v-model="valid">
                      <div class="content px-10 pt-10">
                        <div class="d-flex flex-column text-center justify-center">
                          <h3 class="mb-2">
                            ĐĂNG NHẬP HỆ THỐNG
                          </h3>
                          <h5 class="text-sm grey--text text--darken-2 font-600 mb-8">
                            Bạn có thể đăng nhập bằng email hoặc số điện thoại
                          </h5>
                        </div>

                        <p class="text-14 mb-2">
                          Nhập email hoặc số điện thoại
                        </p>
                        <v-text-field
                          v-model="dangnhap.taikhoan"
                          placeholder="example@mail.com"
                          outlined
                          dense
                          hide-details=""
                          class="mb-4"
                          :rules="[required('Tài khoản')]"
                        />
                        <p class="text-14 mb-2">
                          Mật khẩu
                        </p>
                        <v-text-field
                          v-model="dangnhap.matkhau"
                          type="password"
                          placeholder="*******"
                          outlined
                          dense
                          hide-details=""
                          class="mb-8"
                          :rules="[required('Mật khẩu')]"
                        />

                        <v-btn
                          color="primary"
                          class="text-capitalize font-600 mb-4 py-5"
                          block
                          :disabled="!valid"
                          :loading="loadingStatus"
                          @click="DangNhap"
                        >
                          Đăng nhập
                        </v-btn>
                        <v-row>
                          <v-col
                            cols="12"
                            lg="6"
                            class="mx-auto"
                          >
                            <div class="d-flex align-center mb-4">
                              <v-divider />
                              <span class="mx-3 text-16 grey--text text--darken-1 mb-1">hoặc</span>
                              <v-divider />
                            </div>
                          </v-col>
                        </v-row>
                        <!-- <v-btn
                          color="indigo"
                          class="text-capitalize font-600 white--text mb-4 py-5"
                          block
                        >
                          <v-icon left>
                            mdi-facebook
                          </v-icon>
                          Continue with Facebook
                        </v-btn> -->
                        <!-- <v-btn
                          color="blue"
                          class="text-capitalize font-600 white--text mb-0 py-5"
                          block
                        >
                          <v-icon left>
                            mdi-google
                          </v-icon>
                          Continue with Google
                        </v-btn> -->
                      </div>
                      <div class="text-center my-5">
                        <span class="grey--text text--darken-1">Chưa có tài khoản ? <a
                          href="/dang-ki"
                          class="ms-2 grey--text text--darken-4 font-600"
                        >Đăng kí</a> </span>
                      </div>
                      <div class="py-4 grey lighten-2">
                        <div class="text-center">
                          <span class="grey--text text--darken-1">Quên mật khẩu ? <a
                            href="#"
                            class="ms-2 grey--text text--darken-4 font-600"
                          >Reset</a> </span>
                        </div>
                      </div>
                    </v-form>
                  </v-card>
                </v-dialog>
              </template>
              <template v-else>
                <v-menu
                  open-on-hover
                  bottom
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      elevation="0"
                      fab
                      small
                      class="mr-3 "
                      color="grey lighten-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-account</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item>
                      <v-list-item-title>Xin chào, {{ UserData.ten }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title><strong>Số dư:</strong> {{ new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(UserData.sodu) }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title><strong>Cọc:</strong> {{ new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(UserData.dongbang) }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <router-link to="/ca-nhan/so-dia-chi">
                          <strong>Sổ địa chỉ</strong>
                        </router-link>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <router-link to="/ca-nhan/dau-gia">
                          <strong>Đấu giá của tôi</strong>
                        </router-link>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <router-link to="/ca-nhan/don-hang">
                          <strong>Đơn hàng của tôi</strong>
                        </router-link>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <v-badge
                bordered
                color="error"
                content="12"
                overlap
              >
                <v-btn
                  elevation="0"
                  fab
                  color="grey lighten-2"
                  small
                  @click="drawer = true"
                >
                  <v-icon color="">
                    mdi-cart
                  </v-icon>
                </v-btn>
              </v-badge>
            </div>
          </div>
        </div>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      right
      width="320"
      class="z-999"
    >
      <div class="secondary-siebar-content">
        <v-list-item>
          <v-list-item-avatar class="mr-0">
            <v-icon color="">
              mdi-shopping-outline
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="">
              2 Items
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              icon
              color=""
              @click="drawer = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider />

        <div
          v-for="(item, index) in 10"
          :key="index"
          style=""
        >
          <div class="cart-item d-flex justify-space-between align-center px-2 py-3">
            <div class="d-flex flex-column align-center">
              <v-btn
                class="mx-2"
                fab
                outlined
                dark
                x-small
                color="primary"
              >
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
              <span>1</span>
              <v-btn
                class="mx-2"
                fab
                outlined
                dark
                x-small
                color="primary"
              >
                <v-icon dark>
                  mdi-minus
                </v-icon>
              </v-btn>
            </div>
            <v-avatar
              tile
              size="76"
            >
              <img
                src="@/assets/images/products/Automotive/1.Ford2019.png"
                alt=""
              >
            </v-avatar>
            <div class="col-5">
              <h5 class="text-truncate">
                Ford 2019
              </h5>
              <p class="text-tiny">
                $250.00 x 1
              </p>
              <h5 class="primary--text">
                $250.00
              </h5>
            </div>
            <v-btn
              icon
              color=""
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-divider />
        </div>
      </div>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn
            class="text-capitalize mb-3"
            block
            color="primary"
          >
            Checkout Now ($750.00)
          </v-btn>
          <v-btn
            class="text-cappitalise"
            outlined
            block
            color="primary"
          >
            View Cart
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-app-bar
      class="navbar white"
      :class="{ 'd-none': $route.path == '/sale-page-two'}"
    >
      <div class="container">
        <div class="d-flex justify-space-between align-center">
          <v-btn
            elevation=""
            color="grey lighten-2"
            class="text-capitalize"
            @click="toggleNavbar"
          >
            <v-icon left>
              mdi-view-dashboard
            </v-icon>
            Categories
            <v-icon right>
              mdi-chevron-down
            </v-icon>
          </v-btn>
          <div
            id="navbar-toggle-dropdown"
            class="navbar-toggle-dropdown p-absolute"
            :class="{'open': isToggleNavbar}"
          >
            <navbar />
          </div>
          <div>
            <ul class="navbar-nav navigation-navbar d-flex flex-row">
              <li class="nav-item me-3">
                <a
                  class="nav-link active"
                  href="#"
                >Home</a>

                <ul>
                  <li>
                    <router-link to="/">
                      <p
                        class="mb-0"
                        href="./pages/sale-page.html"
                      >
                        Homepage One
                      </p>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="nav-item me-3">
                <a
                  class="nav-link"
                  href="#"
                >Pages</a>
                <ul>
                  <li>
                    <a
                      href="#"
                      class="d-flex justify-space-between align-center"
                    >
                      <p class="mb-0">Sale Page</p>
                      <v-icon>mdi-menu-right</v-icon>
                    </a>
                    <div class="navbar-submenu">
                      <ul>
                        <li>
                          <router-link to="/sale-page-one">
                            <p class="mb-0">
                              Version 1
                            </p>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/sale-page-two">
                            <p class="mb-0">
                              Version 2
                            </p>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <a
                      href="#"
                      class="d-flex justify-space-between align-center"
                    >
                      <p class="mb-0">Vendor</p>
                      <v-icon>mdi-menu-right</v-icon>
                    </a>
                    <div class="navbar-submenu">
                      <ul>
                        <li>
                          <router-link to="/all-vendors">
                            <p class="mb-0">
                              All Vendors
                            </p>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/vendor-store">
                            <p class="mb-0">
                              Vendor Store
                            </p>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <a
                      href="#"
                      class="d-flex justify-space-between align-center"
                    >
                      <p class="mb-0">Product</p>
                      <v-icon>mdi-menu-right</v-icon>
                    </a>
                    <div class="navbar-submenu">
                      <ul>
                        <li>
                          <router-link to="/search-product">
                            <p class="mb-0">
                              Product Grid/Search
                            </p>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/single-product">
                            <p class="mb-0">
                              Product List/Search
                            </p>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/cart">
                            <p class="mb-0">
                              Cart
                            </p>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/checkout-alternative">
                            <p class="mb-0">
                              Checkout Alternative
                            </p>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </li>
              <li class="nav-item me-3">
                <a
                  class="nav-link"
                  href="#"
                >User Account</a>
                <ul>
                  <li>
                    <a
                      href="#"
                      class="d-flex justify-space-between align-center"
                    >
                      <p class="mb-0">Address</p>
                      <v-icon>mdi-menu-right</v-icon>
                    </a>
                    <div class="navbar-submenu">
                      <ul>
                        <li>
                          <router-link to="/add-address">
                            <p class="mb-0">
                              Add Address
                            </p>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/list-address">
                            <p class="mb-0">
                              List Address
                            </p>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <a
                      href="#"
                      class="d-flex justify-space-between align-center"
                    >
                      <p class="mb-0">Orders</p>
                      <v-icon>mdi-menu-right</v-icon>
                    </a>
                    <div class="navbar-submenu">
                      <ul>
                        <li>
                          <router-link to="/order-list">
                            <p class="mb-0">
                              Order List
                            </p>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/order-details">
                            <p class="mb-0">
                              Order Details
                            </p>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <a
                      href="#"
                      class="d-flex justify-space-between align-center"
                    >
                      <p class="mb-0">Profile</p>
                      <v-icon>mdi-menu-right</v-icon>
                    </a>
                    <div class="navbar-submenu">
                      <ul>
                        <li>
                          <router-link to="/view-profile">
                            <p class="mb-0">
                              View Profile
                            </p>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/edit-profile">
                            <p class="mb-0">
                              Edit Profile
                            </p>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <a
                      href="#"
                      class="d-flex justify-space-between align-center"
                    >
                      <p class="mb-0">Support Tickets</p>
                      <v-icon>mdi-menu-right</v-icon>
                    </a>
                    <div class="navbar-submenu">
                      <ul>
                        <li>
                          <router-link to="/all-tickets">
                            <p class="mb-0">
                              All Tickets
                            </p>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/ticket-details">
                            <p class="mb-0">
                              Ticket Details
                            </p>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <router-link to="/wishlist">
                      <p class="mb-0">
                        Wishlist
                      </p>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="nav-item me-3">
                <a
                  class="nav-link"
                  href="#"
                >Vendor Account</a>
                <ul>
                  <li>
                    <router-link to="/dashboard">
                      <p class="mb-0">
                        Dashboard
                      </p>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/product-list">
                      <p class="mb-0">
                        Product List
                      </p>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/edit-product">
                      <p class="mb-0">
                        Edit Product
                      </p>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/profile">
                      <p class="mb-0">
                        Profile
                      </p>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="nav-item me-3">
                <router-link
                  class="nav-link"
                  to="/order-list"
                >
                  <p class="mb-0">
                    Track My Orders
                  </p>
                </router-link>
              </li>
              <li class="nav-item me-3">
                <router-link
                  class="nav-link"
                  to="/landing"
                >
                  <p class="mb-0">
                    Back to Demo
                  </p>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import HeaderNavbar from '@/views/home/header-navbar'
  import Navbar from '@/views/home/navbar'
  import { directive as onClickaway } from 'vue-clickaway'
  import validations from '@/plugins/helpers/validations'
  import { HashPassword } from '@/plugins/helpers/hash'
  export default {
    name: 'BaseAppBar',
    directives: {
      onClickaway: onClickaway,
    },
    components: {
      HeaderNavbar,
      Navbar,
    },
    data: () => ({
      dangnhap: {
        taikhoan: '',
        matkhau: '',
      },
      valid: false,
      colorNav: false,
      dialog: false,
      isToggleNavbar: false,
      items: [
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me 2' },
      ],
      itemTwo: ['Foo', 'Bar', 'Fizz', 'Buzz'],
      drawer: false,
      group: null,

    }),
    computed: {
      ...mapGetters(['isAuthenticated', 'System', 'SignIn', 'authStatus', 'authError', 'UserData']),
      disableStatus () {
        if (this.authStatus === 'error' || this.authStatus === '') return false
        return true
      },
      loadingStatus () {
        if (this.authStatus === 'loading') return true
        return false
      },
      openStatus () {
        if (this.authStatus === 'opening') return true
        return false
      },
    },
    watch: {
      SignIn: function (val) {
        this.dialog = val
      },
      dialog: function (val) {
        if (val === false) this.ToggleSignIn()
      },
      disableStatus: function (val) {
        this.valid = !val
      },
    },
    methods: {
      ...mapActions(['ToggleSignIn', 'AUTH_REQUEST']),
      ...validations,
      toggleNavbar () {
        if (this.$route.path === '/') {
          this.isToggleNavbar = false
        } else {
          this.isToggleNavbar = !this.isToggleNavbar
        }
      },
      DangNhap () {
        const logininfo = Object.assign({}, this.dangnhap)
        const request = {
          taikhoan: logininfo.taikhoan,
          matkhau: HashPassword(logininfo.matkhau),
        }
        this.$store.dispatch('AUTH_REQUEST', request).then(() => {
          if (this.isAuthenticated) {
            window.location.reload()
          }
        }).catch(error => {
          this.$toast.error(error, { position: 'top' })
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  $z-index-sub-topbar: 2;
  $md: 959px;
  $z-99: 99;
  .z-999{
    z-index: 999;
  }
  .top-bar {
    height: 40px;
    display: flex;
    align-items: center;
  }
  .sale-page-one {

    .sub-topbar {
      &.v-app-bar--is-scrolled  {
        position: relative;
        .dropdown-ecommerce {
          display: none;
        }
      }
    }

    .navbar {
      &.v-app-bar--is-scrolled {
        top: 0;
        margin-bottom: 0px;
      }
    }

  }
  .sale-page-two {

    .sub-topbar {
      &.v-app-bar--is-scrolled  {
        position: relative;
        .dropdown-ecommerce {
          display: none;
        }
      }
    }

    .navbar {
      &.v-app-bar--is-scrolled {
        top: 0;
        margin-bottom: 0px;
      }
    }

  }
  .sub-topbar {
    position: relative;
    box-shadow: none !important;
    width: 100%;
    height: 80px !important;
    z-index: $z-99;
    .dropdown-ecommerce {
      display: none;
    }
    &.v-app-bar--fixed {
      position: unset;
    }
    // z-index: $z-index-sub-topbar;

    &.v-app-bar--is-scrolled {
      position: fixed;
      top: 0;
      box-shadow: none !important;
      .dropdown-ecommerce {
        display: block;
      }
    }
    @media (max-width: $md){

    }
  }

  .navbar {
    height: 60px;
    width: 100%;
    z-index: 2;
    box-shadow: rgb(43 52 69 / 10%) 0px 16px 16px -16px !important;
    &.v-app-bar--is-scrolled {
      position: relative;
      top: 64px;
      margin-bottom: 64px;
      box-shadow: rgb(43 52 69 / 10%) 0px 16px 16px -16px !important;
      // box-shadow: none !important;
    }
    @media (max-width: 992px){
      display: none;
    }
  }
  .search-bar {
    .search-bar-dropdown {
      position: absolute;
      right: 0;
      border-radius:22px;

      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      height: 40px;
      box-shadow: none !important;
      @media (max-width: $md){
        display: none;
      }
    }
    ::v-deep .v-text-field__details {
      display: none ;
    }
  }

  // toggleNavbarButton
  .navbar-toggle-dropdown {

    z-index: 98;
    display: none;
    &.open {
      display: block;
      top: 64px;

    }
  }

</style>
